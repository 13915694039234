import React, { createContext, useContext, useEffect, useState } from 'react'
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { database } from '../../firebase';
import { useLoading } from '../loader/LoaderProvider';

const GlobalContext = createContext()

function GlobalProvider({ children }) {

  const { setLoading } = useLoading();
  const [clubData, setClubData] = useState([])
  const [cities, setCities] = useState([])
  const [admins, setAdmins] = useState([])

  useEffect(() => {
    fetch();
  }, []);
  const fetch = async () => {
    setLoading(true);
    getDocs(query(collection(database, "Cities"))).then((data) => {
      const cities = data.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      setCities(cities);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      console.log('get cities error', error)
    })

    getDocs(query(collection(database, "Clubs"), orderBy("createdAt", "desc"))).then((data) => {
      const allClubData = data.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      setClubData(allClubData);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      console.log('get clubs error', error)
    })

    getDocs(query(collection(database, "Users"), where("role", "==", "Admin"), where("isActive", "==", true))).then((data) => {
      const allAdmins = data.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      setAdmins(allAdmins);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      console.log('get admins error', error)
    })
  };

  return (
    <GlobalContext.Provider value={{ clubData, setClubData, cities, setCities, admins, setAdmins }}>
      {children}
    </GlobalContext.Provider>
  )
}
export default GlobalProvider
export const useGlobal = () => useContext(GlobalContext)